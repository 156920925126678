<template>
  <div class="order-evaluate">
    <div class="content-div">
      <div class="title">您对技师满意吗？</div>
      <div class="attendantInfo">
        <img
          class="attendantImg"
          :src="artificerAvatar"
          alt=""
          v-if="artificerAvatar"
        />
        <img
          class="attendantImg"
          src="../../assets//img//noImg.png"
          alt=""
          v-else
        />
        <span class="attendantName">{{ artificerNickName }}</span>
      </div>
      <div class="evaluateLabel">
        <span
          :class="[
            'labelLi',
            select_label.includes(item.evaluateLabelId) ? 'labelLiSelect' : ''
          ]"
          @click="clickSelectLabel(item)"
          v-for="item in labelList"
          :key="item.evaluateLabelId"
          >{{ item.evaluateLabelName }}</span
        >
      </div>
    </div>
    <div class="content-div">
      <div class="title">您对服务满意吗？</div>
      <p class="evaluateLi">
        <span class="evaluateTitle">服务技能</span
        ><van-rate v-model="skill_rate" size="20" color="orange" />
      </p>
      <p class="evaluateLi">
        <span class="evaluateTitle">服务态度</span
        ><van-rate v-model="attitude_rate" size="20" color="orange" />
      </p>
    </div>
    <van-field
      v-model="content"
      rows="6"
      autosize
      type="textarea"
      :border="false"
      placeholder="请输入您对技师想要说的话..."
    />
    <div class="operate-div">
      <p class="operateBtn" @click="addCommentArtificer">提交评价</p>
    </div>
  </div>
</template>

<script>
import { getCommentArtificerDetails, addCommentArtificer } from "@/api/order";
export default {
  data() {
    return {
      labelList: [],
      artificerAvatar: "",
      artificerNickName: "",
      select_label: [],
      select_label_name: [],
      skill_rate: 5,
      attitude_rate: 5,
      content: ""
    };
  },
  created() {
    this.getCommentArtificerDetails();
  },
  methods: {
    // 提交评价
    addCommentArtificer() {
      if (this.select_label.length == 0 && !this.content) {
        this.$toast("请选择标签/添加评论内容");
      } else {
        let data = {
          artificerId: this.$route.query.artificerId,
          orderId: this.$route.query.orderId,
          serviceSkill: this.skill_rate,
          serviceAttitude: this.attitude_rate,
          commentContent: ""
        };
        if (this.select_label.length > 0 && this.content) {
          data.commentContent =
            this.select_label_name.toString() + "," + this.content;
        } else if (this.select_label.length > 0 && !this.content) {
          data.commentContent = this.select_label_name.toString();
        } else {
          data.commentContent = this.content;
        }
        addCommentArtificer(data).then(res => {
          if (res.data) {
            this.$toast("评价成功");
            this.$router.go(-1);
          }
        });
      }
    },
    // 点击选中
    clickSelectLabel(item) {
      if (this.select_label.includes(item.evaluateLabelId)) {
        let index = this.select_label.indexOf(item.evaluateLabelId);
        this.select_label.splice(index, 1);
        this.select_label_name.splice(index, 1);
      } else {
        this.select_label.push(item.evaluateLabelId);
        this.select_label_name.push(item.evaluateLabelName);
      }
    },
    // 获取技师信息
    getCommentArtificerDetails() {
      getCommentArtificerDetails({
        artificerId: this.$route.query.artificerId
      }).then(res => {
        this.artificerAvatar = res.data.artificerAvatar;
        this.artificerName = res.data.artificerName;
        this.labelList = res.data.evaluateLabelList;
      });
    }
  }
};
</script>
<style lang="less" scoped>
.order-evaluate {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f7f6f6;
  padding: 0.4rem 0.4rem 0;
  box-sizing: border-box;
  .content-div {
    padding: 0.4rem 0.4rem;
    background: white;
    margin-bottom: 0.3rem;
    border-radius: 0.2rem;
    .title {
      font-size: 0.38rem;
      font-weight: bold;
      margin-bottom: 0.4rem;
    }
    .attendantInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .attendantImg {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
      }
      .attendantName {
        font-size: 0.38rem;
        font-weight: bold;
        margin-top: 0.3rem;
      }
    }
    .evaluateLabel {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0.2rem;
      .labelLi {
        min-width: 30%;
        background: rgb(243, 243, 243);
        margin-top: 0.2rem;
        text-align: center;
        padding: 0.25rem 0;
        border-radius: 0.4rem;
        &:not(:nth-child(3n + 1)) {
          margin-left: 0.2rem;
        }
      }
      .labelLiSelect {
        background: #e82b2f;
        color: #fff;
      }
    }
    .evaluateLi {
      margin-top: 0.3rem;
      display: flex;
      align-items: center;
      .evaluateTitle {
        margin-right: 0.4rem;
      }
    }
  }
  .van-cell {
    margin-bottom: 2rem;
    border-radius: 0.2rem;
    font-size: 0.28rem;
  }
  .operate-div {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: white;
    padding: 0.2rem 0.4rem;
    box-sizing: border-box;
    .operateBtn {
      background: #e82b2f;
      color: #fff;
      font-weight: bold;
      text-align: center;
      padding: 0.4rem;
      font-size: 0.36rem;
      border-radius: 0.6rem;
    }
  }
}
</style>
