import request from "utils/request";
import qs from "qs";

// 获取我的订单列表
export function myOrder(data) {
  return request({
    url: "/wap-api/order/myOrderPage",
    method: "post",
    hds: 2,
    data: data
  });
}
// 预约下单--获取订单内容信息
export function orderAppointmentOrder(data) {
  return request({
    url: "/wap-api/order/appointmentOrder",
    method: "post",
    hds: 2,
    data: data
  });
}
// 立即下单
export function addOrder(data) {
  return request({
    url: "/wap-api/order/addOrder",
    method: "post",
    hds: 2,
    data: data
  });
}
// 获取订单详情
export function getLsOrderDetailOut(query) {
  return request({
    url: "/wap-api/order/getLsOrderDetailOut",
    method: "get",
    params: query
  });
}
// 获取加钟订单详情
export function getClockOrderDetailOut(query) {
  return request({
    url: "/wap-api/order/getClockOrderDetailOut",
    method: "get",
    params: query
  });
}
// 服务评价--获取技师信息和标签
export function getCommentArtificerDetails(query) {
  return request({
    url: "/wap-api/order/getCommentArtificerDetails",
    method: "get",
    params: query
  });
}
// 添加订单/技师评价
export function addCommentArtificer(data) {
  return request({
    url: "/wap-api/order/addCommentArtificer",
    method: "post",
    hds: 2,
    data: data
  });
}
// 订单支付
export function jsPayOrder(query) {
  return request({
    url: "/wap-api/order/jsPayOrder",
    method: "get",
    params: query
  });
}
// 删除订单
export function delOrder(query) {
  return request({
    url: "/wap-api/order/delOrder",
    method: "get",
    params: query
  });
}
// 获取加钟项目列表
export function getClockProject(query) {
  return request({
    url: "/wap-api/order/getClockProject",
    method: "get",
    params: query
  });
}

// 根据订单id获取隐私号接口
export function virtualNumber(orderId) {
  return request({
    url: `/wap-api/dongxin/get/axb/virtual/number?orderId=${orderId}`,
    method: "get",
  });
}

// 获取技师是否超出范围
export function getIsOutRange(data) {
  return request({
    url: "/wap-api/artificer/checkChooseIsOutOfRange",
    method: "post",
    hds: 2,
    data: data
  });
}

